/* Reports.css */


.contain {
  padding: 20px;
  
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h4 {
  margin-top: 60px;
  font-size: 24px;
}

.user {
  margin-top: 60px;
  padding: 10px 20px;
  background-color: #57b960;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.user:hover {
  background-color: #468953;
}

.table-responsive {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow-x: auto;
}

/* Table styles */
.table th,
.table td {
    vertical-align: middle;
}

/* Modal styles */
.modal-dialog {
    max-width: 800px;
}

/* Add hover effect on table rows */
.table tbody tr:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

/* Add border and padding to table cells */
.table td,
.table th {
    border: 1px solid #dee2e6;
    padding: 8px;
}

/* Add border-radius to modal header */
.modal-header {
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    background-color: #007bff;
    color: #fff;
}


/* Add border-radius to modal body */
.modal-body {
    border-radius: 0 0 5px 5px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: center;
}

.table thead th {
  background-color: #57b960;
  color: #fff;
  border: 1px solid #dee2e6;
}

.table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
}

.table-hover tbody tr:hover {
  background-color: #e0f7fa;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-striped tbody tr:nth-child(odd) {
  background-color: #f8f9fb;
}

.page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page .page-item {
  margin: 0 5px;
}

.page .page-item.active {
  background-color: #57b960;
  border-color: #57b960;
  color: #fff;
}

.page .page-item:hover {
  background-color: #468953;
  border-color: #468953;
  color: #fff;
}

@media (max-width: 575px) {
  
  h4 {
    margin-top: 60px;
    font-size: 15px;
  }
  
  .user {
    font-size: 15px;
    margin-top: 60px;
    padding: 10px 20px;
    background-color: #57b960;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .table th,
.table td {
  padding: 12px;
  text-align: center;
  font-size:15px;
}

.table thead th {
  background-color: #57b960;
  color: #fff;
  border: 1px solid #dee2e6;
  font-size:12px;       
}
.table tbody tr:nth-child(even) {
  background-color: #f8f9fa;
  font-size:12px;
}
}
  


  /* Sidebar.css */

#sidebar {
  background-color: #e9ecef;
  height:800px;

}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  color: #343a40;
}

.nav-link:hover {
  color: #007bff;
}

.sidebar-logo {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.sidebar-logo:hover {
  text-decoration: none;
  color: #007bff;
}
  .nav-link {
    color: #495057;
  }

  .nav-link:hover {
    color: #007bff;
    background-color: transparent;
  }

  .sidebar-logo {
    color: #007bff;
  }
/* } */

@media (max-width: 575px) {
  .nav.flex-column.sticky-top {
    padding: 15px;
  }

  #sidebar {
    background-color: #e9ecef;
    height: 100%;
  }
  
}
