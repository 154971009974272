.datatable-container {
    padding: 20px;
  }
  
  .toolbar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .search-input {
    margin-right: 10px;
  }
  
  .download-button {
    margin-left: auto;
  }
  
  .data-table {
    font-size: 14px;
  }
  
  .device-image {
    cursor: pointer;
  }
  
  .device-image-full {
    width: 100%;
    height: auto;
  }
  